*{
  box-sizing: border-box;
}

body, html {
  margin: 0;
  height: 100%;
}

* {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::selection {
  background-color: rgba(132, 39, 255, 0.39);
}

*::-webkit-scrollbar {
  width: .5em;
}

*::-webkit-scrollbar-track {
  background-color: #F9F9F9;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 223, 176, 0.56);
  border-radius: 30px;
  outline: 1px solid transparent;
}

.text-center {
  text-align: center;
}

.mt-1\/2 {
  margin-top: .5rem;
}
.ml-1\/4 {
  margin-left: .25rem;
}
.ml-1\/2 {
  margin-left: .5rem;
}
.mr-1\/2 {
  margin-right: .5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-1\/2 {
  margin-bottom: .5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-1 {
  margin-right: 1rem;
}

.m-0 {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.w-100 {
  width: 100%;
}

.d-none {
  display: none;
}

.appears {
  animation: ani-appears 250ms ease-in;
}

.underline:hover {
  text-decoration: underline;
}

@keyframes ani-appears {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bounce {
  animation: ani-bounce 250ms ease-in;
}

@keyframes ani-bounce {
  from {
    transform: scale(.6) rotateZ(30deg);
  }
  50% {
    transform: scale(.9) rotateZ(30deg);
  }
  to {
    transform: scale(.6) rotateZ(30deg);
  }
}
